<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="title==2 ? $t('SelectBank') : $t('BankCard')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div style="padding-top: 50px;">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="No more"
        @load="onLoad"
      >
        <div
          style="margin-top: 1rem;"
          v-for="item in list"
          :key="item.id"
          :title="item"
          @click="onItemClick(item)"
        >
          <van-swipe-cell>
            <div class="cardV">
              <span class="cardCode">{{ item.number }}</span>
              <span class="cardName">{{ item.bank_name }}</span>
            </div>
            <template #right>
              <div
                style="padding-right: 1rem; height: 100%; display: flex; align-items: center; justify-content: center;"
              >
                <img
                  style="width: 2.87rem; height: 2.87rem;"
                  src="../../assets/img/delete.png"
                />
              </div>
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
      <div class="addTv" @click="onAddClick()">+ {{ $t("Add") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      title: 1,
    };
  },
  created() {
    this.title = this.$route.params.selete;
  },
  methods: {
    async onLoad() {
      const { data } = await this.$http.get("/home/user/userCard");
      if (data) {
        this.loading = false;
        if (data.code === 200) {
          this.finished = true;
          this.list = data.data;
        }
      }
    },
    onAddClick() {
      this.$router.push("/assets/addcard");
    },
    onItemClick(item) {
      if (this.title==1) {

      } else {
        this.$route.params.user = JSON.stringify(item);
        this.$router.back();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cardV {
  height: 6.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-image: url("../../assets/img/card_bg.png");
  background-size: 100% 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cardCode {
    color: #fff;
    font-size: 1.5rem;
    text-align: left;
  }

  .cardName {
    color: #fff;
    font-size: 0.87rem;
    margin-top: 0.2rem;
    text-align: left;
  }
}

.addTv {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3.18rem;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  position: fixed;
  right: 1rem;
  top: 60%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
